import React, { useState, useEffect } from 'react'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useDiningOptions } from '@local/do-secundo-use-dining-options'
import {
  useHasMode,
  useModeHistoryPushCallback
} from '../ModeRouter/ModeRouter'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { CartNotFoundModal } from '../ShareCart/CartNotFoundModal'
import { SharedCartWelcomeModal } from '../ShareCart/SharedCartWelcomeModal'
import { FastLinkNotFoundModal } from '../FulfillmentSelectorModal/FastLink/FastLinkNotFoundModal'

export const FulfillmentRedirect: React.FC = () => {
  const {
    loading: availabilityLoading,
    orderingAvailable,
    standardOrderingAvailable
  } = useAvailability()
  const { selected, valid, loading: fulfillmentLoading } = useFulfillment()
  const {
    isLoading: diningOptionsLoading,
    fastLinkNotFound,
    independent
  } = useDiningOptions()

  const [sharedCartWelcomeModalOpen, setSharedCartWelcomeModalOpen] =
    useState(false)
  const [fastLinkNotFoundModalOpen, setFastLinkNotFoundModalOpen] =
    useState(false)
  const [cartNotFoundModalOpen, setCartNotFoundModalOpen] = useState(false)
  const [redirectToFulfillmentOnClose, setRedirectToFulfillmentOnClose] =
    useState(false)

  const { initialCartNotFound, initialCartMetadata, setInitialCartNotFound } =
    useCart()

  const loading =
    diningOptionsLoading || availabilityLoading || fulfillmentLoading
  const isFulfillment = useHasMode('fulfillment')
  const redirectToFulfillment = useModeHistoryPushCallback({
    mode: 'fulfillment'
  })

  // Will open modal prior to having fulfillment selected e.g. on first menu load
  useEffect(() => {
    if (!loading) {
      const fulfillmentModalShouldShow =
        (!selected || !valid) &&
        (orderingAvailable || (standardOrderingAvailable && !independent))

      if (initialCartNotFound) {
        setCartNotFoundModalOpen(true)
        if (fulfillmentModalShouldShow) {
          setRedirectToFulfillmentOnClose(true)
        }
      } else if (initialCartMetadata?.metadata?.name) {
        setSharedCartWelcomeModalOpen(true)
      } else if (fastLinkNotFound) {
        setFastLinkNotFoundModalOpen(true)
      } else {
        if (fulfillmentModalShouldShow && !isFulfillment) {
          redirectToFulfillment()
        }
      }
    }
  }, [
    loading,
    orderingAvailable,
    standardOrderingAvailable,
    initialCartNotFound,
    fastLinkNotFound
  ])

  return (
    <>
      {cartNotFoundModalOpen && (
        <CartNotFoundModal
          onClose={() => {
            setInitialCartNotFound(false)
            setCartNotFoundModalOpen(false)
            if (redirectToFulfillmentOnClose) {
              redirectToFulfillment()
            }
          }}
        />
      )}
      {sharedCartWelcomeModalOpen && (
        <SharedCartWelcomeModal
          onClose={() => {
            setSharedCartWelcomeModalOpen(false)
          }}
        />
      )}
      {fastLinkNotFoundModalOpen && (
        <FastLinkNotFoundModal
          onClose={() => setFastLinkNotFoundModalOpen(false)}
        />
      )}
    </>
  )
}
