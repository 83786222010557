import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@local/do-secundo-modal'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { Link } from '../Link/Link'
import { ButtonSpacer } from '../ButtonSpacer/ButtonSpacer'
import { useGetCart } from '../CartQuery/CartQuery'
import { FutureEstimate } from '../FutureEstimate/FutureEstimate'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import styles from './CheckoutErrorModals.module.css'

export const UnavailableModal = () => {
  const { deleteCartGuid } = useGetCart()
  const { getRestaurantPath } = useRestaurant()

  return (
    <Modal header="Your catering order can't be completed">
      <div className={styles.wrapper}>
        <p className={styles.paragraph}>
          We're sorry, but your order can no longer be completed at this time.
        </p>
        <Link
          onClick={deleteCartGuid}
          to={getRestaurantPath('?mode=catering')}
          style='primaryWide'
        >
          Schedule a new order
        </Link>
      </div>
    </Modal>
  )
}

export const TimeChangedModal = ({ onSubmit, loading }) => {
  const { deleteCartGuid } = useGetCart()
  const { getRestaurantPath } = useRestaurant()

  return (
    <Modal header='Your catering order time has changed'>
      <div className={styles.wrapper}>
        <p className={styles.paragraph}>
          Your order time is no longer available. The new time for your catering
          order is{' '}
          <strong>
            <FutureEstimate />
          </strong>
          .
        </p>
        <Button
          data-testid='submit'
          variant={ButtonVariant.PRIMARY}
          onClick={onSubmit}
          loading={loading}
        >
          Yes, submit my order
        </Button>
        <ButtonSpacer vertical />
        <Link
          data-testid='cancel'
          onClick={deleteCartGuid}
          to={getRestaurantPath('?mode=catering')}
          style='secondaryWide'
        >
          Schedule a new order
        </Link>
      </div>
    </Modal>
  )
}

TimeChangedModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
}
