import React from 'react'
import { string } from 'yup'
import { TelephoneField } from '@toasttab/buffet-pui-forms'
import { useCustomer } from '../../../api/customer'
import { Cart } from '../../../types/cart'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { useFormikContext } from 'formik'

export const Component = ({
  customer,
  cart
}: {
  customer: Customer
  cart: Cart
}) => {
  const { setFieldValue } = useFormikContext()
  const { onChange } = useCustomer(cart, customer)

  return (
    <div>
      <TextInput
        required
        containerClassName='mb-4'
        autoComplete='section-customer given-name'
        testId='customer_first_name'
        id='customer_first_name'
        label='First name'
        name='customerFirstName'
        type='text'
        autoFocus
        value={customer.firstName}
        onChange={(event) => {
          setFieldValue('customerFirstName', event.target.value)
          setFieldValue('optedInToLoyaltyLookup', false)
          onChange({ ...customer, firstName: event.target.value }, cart)
        }}
      />
      <TextInput
        required
        containerClassName='mb-4'
        autoComplete='section-customer family-name'
        testId='customer_last_name'
        id='customer_last_name'
        label='Last name'
        name='customerLastName'
        type='text'
        value={customer.lastName}
        onChange={(event) => {
          setFieldValue('customerLastName', event.target.value)
          onChange({ ...customer, lastName: event.target.value }, cart)
        }}
      />
      <TextInput
        required
        containerClassName='mb-4'
        autoComplete='section-customer email'
        testId='customer_email'
        id='customer_email'
        label='Email'
        name='customerEmail'
        type='email'
        value={customer.email}
        onChange={(event) => {
          setFieldValue('customerEmail', event.target.value)
          onChange({ ...customer, email: event.target.value }, cart)
        }}
      />
      <TelephoneField
        required
        containerClassName='mb-4'
        allowEmptyFormatting={false}
        autoComplete='section-customer tel-national'
        testId='customer_tel'
        id='customer_tel'
        label='Phone'
        name='customerTel'
        type='tel'
        helperText='By providing a mobile number, you give Toast permission to contact you
      using automated text messages to provide transactional messages such as
      order status updates.'
        value={customer.phone}
        onChange={(event) => {
          setFieldValue('customerTel', event.value)
          onChange({ ...customer, phone: event.value }, cart)
        }}
      />
    </div>
  )
}

export const getInitialValues = ({
  customer
}: {
  customer: Customer | null
}) => ({
  customerFirstName: customer?.firstName ?? '',
  customerLastName: customer?.lastName ?? '',
  customerEmail: customer?.email ?? '',
  customerTel: customer?.phone ?? ''
})

export interface Customer {
  email: string
  firstName: string
  lastName: string
  phone: string
}

export const getArgsForSubmit = ({ values }): { customer: Customer } => ({
  customer: {
    email: values.customerEmail,
    firstName: values.customerFirstName,
    lastName: values.customerLastName,
    phone: values.customerTel.toString()
  }
})

export const getValidationSchema = () => ({
  customerFirstName: string().trim().required('Required'),
  customerLastName: string().trim().required('Required'),
  customerEmail: string()
    .trim()
    .email('Must be valid email')
    .required('Required'),
  customerTel: string()
    .trim()
    .matches(/^\d{10}$/, 'Must be a 10 digit number')
    .required('Required')
})
