import React from 'react'
import { SelectionViewModel, useAddItemToCart } from '../CartMutation'
import { ItemDetails, UpdateItemFormModal } from './UpdateItemFormModal'
import { useMenus } from '../../hooks/use-menus'
import {
  findItemInMenus,
  getItemQuantityRange
} from '../../utils/menu-group-helpers'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  onCloseLink: string
}

export const AddItem: React.FC<Props> = ({ onCloseLink }) => {
  const { itemGuid, itemGroupGuid } = useParams<{
    itemGuid: string
    itemGroupGuid: string
  }>()

  const navigate = useNavigate()
  const onClose = () => {
    navigate(onCloseLink, { replace: true })
  }

  const { menus, loading, error, menuItemQuantityRules } = useMenus()

  const addItemToCart = useAddItemToCart()
  const handleAddItem = (selection: SelectionViewModel) =>
    addItemToCart(selection).then((cart) => {
      onClose()
      return cart
    })

  if (!itemGuid || !itemGroupGuid) {
    return null
  }

  const item = menus && findItemInMenus(menus, itemGroupGuid, itemGuid)
  const basePrice = item?.price || 0
  const allowedQuantityRange = getItemQuantityRange(
    itemGuid,
    itemGroupGuid,
    menuItemQuantityRules
  )

  const itemDetails: ItemDetails | undefined = item && {
    itemGuid,
    itemGroupGuid,
    name: item.name,
    description: item.description,
    menuItemPrice: item.price || 0,
    calories: item.calories || 0,
    modifierGroups: item.modifierGroups,
    allowedQuantityRange,
    // pass to children resolvers
    imageLink: item.imageLink,
    idString: item.guid
  }

  return (
    <UpdateItemFormModal
      updateCart={handleAddItem}
      loading={loading}
      error={error}
      itemDetails={itemDetails}
      basePrice={basePrice}
      onClose={onClose}
      itemGuid={itemGuid}
      itemGroupGuid={itemGroupGuid}
      mayDelete={false}
    />
  )
}
