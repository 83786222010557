import React from 'react'
import { SelectField, TextInputField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getIn, useFormikContext } from 'formik'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import {
  defaultStateStrings,
  getStates
} from '@toasttab/buffet-pui-country-utilities'
import { Label } from '@toasttab/buffet-pui-input-base'
import { HelperText } from '@toasttab/buffet-pui-text-base'

export const TaxExempt = () => {
  const { setFieldValue, values } = useFormikContext()
  const isTaxExempt = getIn(values, 'isTaxExempt')

  const { restaurantInfo, ooConfig } = useRestaurant()

  if (!ooConfig?.taxExemptEnabled) return null

  const country = restaurantInfo?.address?.country

  if (country !== 'US' && country !== 'CA') {
    return null
  }

  const states = getStates(country, defaultStateStrings)

  const toggleTaxExempt = async () => {
    if (isTaxExempt) {
      await setFieldValue('taxExemptId', undefined)
      await setFieldValue('taxExemptState', undefined)
    }
    setFieldValue('isTaxExempt', !isTaxExempt)
  }

  return (
    <div className='mb-4'>
      {isTaxExempt && (
        <div>
          <div>
            <Label>Tax Exempt Account</Label>
          </div>
          <div className={'flex flex-wrap gap-4'}>
            <TextInputField
              testId='tax-exempt-input-field'
              label='ID'
              type='text'
              name='taxExemptId'
              id='tax_exempt_id'
              key='tax-exempt-id'
              required
              maxLength={12}
              containerClassName={'pb-2 w-64'}
            />
            <SelectField
              name={'taxExemptState'}
              label={country === 'US' ? 'State' : 'Province'}
              options={states}
              required
              containerClassName={'w-64'}
            />
          </div>
          <HelperText
            helperText={`By providing a tax exempt ID you agree to provide any supporting documentation needed to ${
              restaurantInfo?.name ?? 'the restaurant'
            }.`}
          />
        </div>
      )}
      <Button
        testId='tax-exempt-button'
        variant='link'
        className='-ml-4'
        onClick={toggleTaxExempt}
      >
        {isTaxExempt ? 'Remove tax exempt account' : 'My order is tax exempt'}
      </Button>
    </div>
  )
}
