import { NamespacedStorage } from '@local/do-secundo-storage'
import { DiningOptionBehavior } from '../../types/cart'
import { isFulfillmentTimeValid } from '../FulfillmentSelectorModal/fulfillment-helpers'
import { FulfillmentState } from './FulfillmentProvider'
import {
  FulfillmentQueryParams,
  queryParamsToDeliveryInfo
} from '../FulfillmentSelectorModal/LocationPicker/navigation'

const availabilityError = {
  error: new Error('Ordering not available')
}

export const initialReducerState = ({
  fulfillmentStorage,
  availability,
  orderingAvailable,
  fulfillmentQueryParams
}: {
  fulfillmentStorage: NamespacedStorage
  availability: Record<DiningOptionBehavior, boolean>
  orderingAvailable: boolean
  fulfillmentQueryParams?: FulfillmentQueryParams
}): FulfillmentState | { error: any } => {
  const { get } = fulfillmentStorage

  if (!orderingAvailable) {
    return availabilityError
  }

  const fulfillmentTime: string =
    fulfillmentQueryParams?.dateTime || get('fulfillmentTime') || null

  const savedDiningOptionBehavior: DiningOptionBehavior =
    fulfillmentQueryParams?.behavior ||
    get('diningOptionBehavior') ||
    'TAKE_OUT'

  const diningOptionBehavior: DiningOptionBehavior | undefined = availability[
    savedDiningOptionBehavior
  ]
    ? savedDiningOptionBehavior
    : availability['TAKE_OUT']
    ? 'TAKE_OUT'
    : availability['DELIVERY']
    ? 'DELIVERY'
    : undefined

  if (!diningOptionBehavior) {
    return availabilityError
  }

  const deliveryInfo =
    queryParamsToDeliveryInfo(fulfillmentQueryParams) ||
    get('deliveryInfo') ||
    undefined

  return {
    deliveryInfo,
    fulfillmentDateTime: fulfillmentTime,
    valid: isFulfillmentTimeValid(fulfillmentTime),
    selected: false,
    loading: false,
    diningOptionBehavior
  }
}
