import React from 'react'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useQuery } from 'react-query'
import { getEventTypeForBehavior } from '../../api/forms'
import { ErrorMessage } from '../../types/cart'
import {
  SERVICES,
  SERVICE_AVAILABILITY,
  useServiceAvailability
} from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import {
  getArgsForSubmit,
  getInitialValues,
  getValidationSchema
} from './utils'
import { EventType } from '../../types/form'
import { useResponseBody } from '../../hooks/use-response-body'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { CheckoutFormBody } from './CheckoutFormBody'
import { useGetCart } from '../CartQuery/CartQuery'
import { useCartValidator } from '../Cart/Validation/CartValidation'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { ErrorComponent } from '@local/do-secundo-error'
import { Formik } from 'formik'
import { getCheckTotal } from '../../utils/cart-helpers'

interface Props {
  onSubmit: (...opts: any[]) => Promise<void>
  error?: Error | string
}

export const CheckoutForm: React.FC<Props> = ({ onSubmit, error }) => {
  const { restaurantGuid, restaurantInfo } = useRestaurant()

  const country = restaurantInfo?.address?.country || 'US'

  const fulfillmentContext = useFulfillment()
  const { cart } = useGetCart()
  const { cartValid } = useCartValidator(cart)
  const { willEarnLoyalty, setWillEarnLoyalty } = useCart()

  const {
    data: eventType,
    isLoading: isEventTypeLoading,
    isError: isEventTypeError,
    error: eventTypeError
  } = useQuery<EventType, Response>(
    `event-type-${fulfillmentContext.diningOptionBehavior}`,
    () =>
      getEventTypeForBehavior(
        restaurantGuid,
        fulfillmentContext.diningOptionBehavior
      ),
    { enabled: !!fulfillmentContext.diningOptionBehavior }
  )

  const eventTypeErrorBody = useResponseBody<ErrorMessage>(eventTypeError)

  const { [SERVICES.PLACE_ORDER]: placeOrderService } = useServiceAvailability()

  const isPlaceOrderDegraded = React.useMemo(
    () =>
      placeOrderService &&
      placeOrderService.status === SERVICE_AVAILABILITY.DEGRADED,
    [placeOrderService]
  )

  if (fulfillmentContext.loading || isEventTypeLoading) {
    return <Loading variant={LoadingVariant.SECONDARY} />
  }

  const isError = isEventTypeError || fulfillmentContext.error
  const errorMsg = isEventTypeError
    ? eventTypeErrorBody?.message
    : fulfillmentContext.error
  if (isError) {
    return <ErrorComponent error={errorMsg} isActionable={false} />
  }

  const initialValues = getInitialValues({
    fulfillmentContext,
    formFields: eventType?.formFields || [],
    customer: cart?.order.checks[0].customer,
    paymentType: getCheckTotal(cart) > 0 ? 'CREDIT' : 'OTHER',
    preComputedTips: cart?.preComputedTips
  })

  const validationSchema = getValidationSchema({
    fulfillmentContext,
    formFields: eventType?.formFields || [],
    country
  })

  // See this regarding custom validate prop instead of validationSchema so
  // that we can pass custom context to yup.
  // https://github.com/jaredpalmer/formik/issues/1359
  return (
    <Formik
      enableReinitialize
      data-testid='checkout-form'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const submitArgs = getArgsForSubmit({
            fulfillmentContext,
            values,
            eventType: eventType!!
          })

          // since we enroll loyalty asynchronously, we will use this to show
          // the confirmation before applied loyalty appears on the order
          setWillEarnLoyalty(
            willEarnLoyalty || Boolean(submitArgs.loyaltyEnroll)
          )

          await onSubmit(submitArgs)
        } catch (e) {
          console.warn('onSubmit', e)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, isSubmitting, isValid: isFormValid, handleSubmit }) => {
        return (
          <CheckoutFormBody
            eventType={eventType}
            handleSubmit={handleSubmit}
            customer={CustomerInfo.getArgsForSubmit({ values }).customer}
            values={values}
            isSubmitting={isSubmitting}
            canCheckout={
              isFormValid && !fulfillmentContext.updating && cartValid
            }
            isPlaceOrderDegraded={isPlaceOrderDegraded}
            error={error}
          />
        )
      }}
    </Formik>
  )
}
