import React from 'react'
import { ApolloProvider } from '@apollo/client'
import 'isomorphic-fetch'
import '../../utils/lifecycle'
import { getApolloClient } from '../../apollo/apollo-client'
import { OnlineOrderingApp } from './OnlineOrderingApp'
import { OOGlobalsProvider } from '@local/do-secundo-oo-globals-provider'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { QueryClientProvider, QueryClient } from 'react-query'
import { HelmetProvider } from 'react-helmet-async'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false
    }
  }
})

const AppComponent = ({
  ooGlobals = window.OO_GLOBALS || {
    devError: {
      message: 'Could not find OO_GLOBALS.'
    },
    enabledFeatureFlags: []
  },
  useMemoryRouter = false
}) => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={getApolloClient()}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <OOGlobalsProvider
              devError={ooGlobals.devError}
              enabledFeatureFlags={ooGlobals.enabledFeatureFlags}
              gatewayBaseUri={ooGlobals.gatewayBaseUri}
              toastwebBaseUri={ooGlobals.toastwebBaseUri}
              restaurantGuid={ooGlobals.restaurantGuid}
              routerBasename={ooGlobals.routerBasename}
              shortUrl={ooGlobals.shortUrl}
              mode={ooGlobals.mode}
              managementGroupGuid={ooGlobals.managementGroupGuid}
              mapboxAccessToken={ooGlobals.mapboxAccessToken}
              toastTakeoutCTAEnabled={Boolean(ooGlobals.toastTakeoutCTAEnabled)}
              isCornucopiaShellRunning={false}
            >
              <OnlineOrderingApp useMemoryRouter={useMemoryRouter} />
            </OOGlobalsProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export const BanquetApp = AppComponent
