import React from 'react'
import { Address, CustomLocationsConfig } from '../../../types/config'
import { Select, SelectItemContainer } from '@toasttab/buffet-pui-dropdowns'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { getAddressForLocation } from '../../../utils/address-helpers'
import { FulfillmentFormValues } from '../../../types/fulfillment'

interface Props {
  customLocationsConfig: CustomLocationsConfig | undefined
  values: FulfillmentFormValues
  setValues: (values: FulfillmentFormValues) => void
}

const addressToString = (address: Address | undefined) => {
  if (!address) return undefined

  return getAddressForLocation(address)
}

export const CustomLocationPicker = ({
  customLocationsConfig,
  values,
  setValues
}: Props) => {
  const { restaurantInfo } = useRestaurant()

  const { standardFulfillment, diningOptionBehavior, customLocationName } =
    values

  if (!diningOptionBehavior || !customLocationsConfig) {
    return null
  }

  const customLocations = customLocationsConfig.locations.filter(
    (location) => location.behavior === diningOptionBehavior
  )

  if (customLocations.length == 0) {
    return null
  }

  const options = customLocations.map((location, index) => ({
    label: location.name,
    subLabel: addressToString(location.address),
    value: index
  }))

  const supportStandardTakeout =
    diningOptionBehavior === 'TAKE_OUT' &&
    customLocationsConfig.standardTakeout &&
    (restaurantInfo?.name ?? restaurantInfo?.locationName)

  if (supportStandardTakeout) {
    options.unshift({
      label: restaurantInfo!!.name ?? restaurantInfo!!.locationName,
      subLabel: addressToString(restaurantInfo!!.address),
      value: -1
    })
  }

  const supportStandardDelivery =
    customLocationsConfig.standardDelivery &&
    diningOptionBehavior === 'DELIVERY'

  if (supportStandardDelivery) {
    options.unshift({
      label: 'Enter delivery address',
      subLabel: undefined,
      value: -1
    })
  }

  const getValue = (customLocationName: string | undefined) => {
    if (customLocationName) {
      const locationIndex = customLocations.findIndex((location) => {
        return location.name === customLocationName
      })

      if (locationIndex >= 0) {
        return locationIndex
      } else {
        return undefined
      }
    } else if (
      (supportStandardTakeout || customLocationsConfig.standardDelivery) &&
      standardFulfillment
    ) {
      return -1
    } else {
      return undefined
    }
  }

  return (
    <Select
      testId='CustomLocationSelect'
      containerClassName='py-4'
      label={
        diningOptionBehavior === 'TAKE_OUT'
          ? 'Pickup location'
          : 'Delivery location'
      }
      placeholder={'Select one'}
      value={getValue(customLocationName)}
      onChange={(value) => {
        if (value === -1) {
          setValues({
            ...values,
            customLocationName: undefined,
            standardFulfillment: true,
            deliveryInfo: undefined
          })
        } else {
          setValues({
            ...values,
            customLocationName: customLocations[value].name,
            standardFulfillment: false,
            deliveryInfo:
              customLocations[value].behavior === 'DELIVERY'
                ? {
                    ...customLocations[value].address,
                    zipCode: customLocations[value].address?.zip
                  }
                : undefined
          })
        }
      }}
      renderItem={(option) => (
        <SelectItemContainer
          index={option.index}
          highlightedIndex={option.highlightedIndex}
          itemProps={option.itemProps}
          key={option.index}
        >
          {option.item.value === -1 && diningOptionBehavior === 'DELIVERY' ? (
            <div className='text-link font-semibold'>
              <AddIcon />
              {option.item.label}
            </div>
          ) : (
            <div>
              {option.item.label}
              {option.item.subLabel && (
                <div className={'text-secondary type-subhead'}>
                  {option.item.subLabel}
                </div>
              )}
            </div>
          )}
        </SelectItemContainer>
      )}
      options={options}
    />
  )
}
