import * as React from 'react'
import PropTypes from 'prop-types'

import { ErrorComponent } from '@local/do-secundo-error'

interface OOGlobals {
  devError?: any
  enabledFeatureFlags: string[]
  gatewayBaseUri: string
  toastwebBaseUri: string
  restaurantGuid: string
  routerBasename: string
  shortUrl: string
  mode: string
  mapboxAccessToken: string
  managementGroupGuid: string
  toastTakeoutCTAEnabled: boolean
  isCornucopiaShellRunning: boolean
}

// @ts-ignore
const OOGlobalsContext = React.createContext<OOGlobals>({})

export const OOGlobalsProvider = ({
  devError,
  enabledFeatureFlags,
  gatewayBaseUri,
  toastwebBaseUri,
  restaurantGuid,
  routerBasename,
  shortUrl,
  managementGroupGuid,
  mode,
  mapboxAccessToken,
  toastTakeoutCTAEnabled,
  isCornucopiaShellRunning,
  children
}: OOGlobals & { children: React.ReactNode }) => {
  const context = {
    devError,
    enabledFeatureFlags,
    gatewayBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    routerBasename,
    shortUrl,
    managementGroupGuid,
    mode,
    mapboxAccessToken,
    toastTakeoutCTAEnabled,
    isCornucopiaShellRunning
  }

  return (
    <OOGlobalsContext.Provider value={context}>
      {children}
    </OOGlobalsContext.Provider>
  )
}

export const OOGlobalsConsumer: React.FC<{
  children: (context: OOGlobals) => React.ReactNode
}> = ({ children }) => (
  <OOGlobalsContext.Consumer>
    {(context) => (
      <>
        {context.devError && (
          <ErrorComponent
            error={context.devError}
            isActionable={false}
            retry={() => {}}
          />
        )}
        {children(context)}
      </>
    )}
  </OOGlobalsContext.Consumer>
)

OOGlobalsConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export const useOOGlobals = () => React.useContext(OOGlobalsContext)

export const MockOOGlobalsProvider = ({
  mocks: {
    enabledFeatureFlags,
    gatewayBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    routerBasename,
    shortUrl,
    managementGroupGuid,
    mode,
    mapboxAccessToken,
    toastTakeoutCTAEnabled,
    isCornucopiaShellRunning
  },
  children
}: {
  mocks: OOGlobals
  children: React.ReactNode
}) => {
  const context = {
    enabledFeatureFlags,
    gatewayBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    routerBasename,
    shortUrl,
    managementGroupGuid,
    mode,
    mapboxAccessToken,
    toastTakeoutCTAEnabled,
    isCornucopiaShellRunning
  }

  return (
    <OOGlobalsContext.Provider value={context}>
      {children}
    </OOGlobalsContext.Provider>
  )
}
