import { CloseButton } from '@local/do-secundo-closebutton'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Progress } from '@local/do-secundo-progress'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import * as React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { formatPhoneNumber } from '../../utils/phone-number'
import { toRelativeDateString } from '../../utils/time-utils'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { CartTable } from '../Cart/CartTable/CartTable'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { RestaurantAddress } from '../RestaurantAddress/RestaurantAddress'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { BackToMenuButton } from './BackToMenuButton/BackToMenuButton'
import { SocialBanner } from './SocialBanner/SocialBanner'
import { useQuery } from 'react-query'
import { getCompletedOrder } from '../../api/orders'
import { formatHours } from '../../utils/restaurant-schedule-helpers'
import styles from './ConfirmPage.module.css'
import { LoyaltyConfirmation } from './LoyaltyConfirmation'
import { ReplateBanner } from './ReplateBanner'
import ConfirmationTag from '../../../packages/GooglePlixels/ConfirmationTag'

export const ConfirmPage = () => {
  const { orderGuid } = useParams<{ orderGuid: string }>()
  const { getRestaurantPath, restaurantGuid, restaurantInfo } = useRestaurant()

  const { isLoading, isError, data } = useQuery('completed-order', () =>
    getCompletedOrder(orderGuid!!)
  )

  if (isLoading && !data) return <Progress />
  if (!data || isError) return <Navigate to={getRestaurantPath()} />

  const completedOrder = data
  const check = completedOrder.checks[0]

  if (!check || !restaurantInfo) {
    return null
  }

  const { estimatedFulfillmentDate } = data
  const restaurantLocation = restaurantInfo.address
  const deliveryInfo = completedOrder.deliveryInfo

  const firstName = check?.customer?.firstName
  const cart = {
    order: completedOrder
  }

  return (
    <div className={styles.confirmPage}>
      <ScrollToTop />
      <>
        <ConfirmationTag completedOrder={completedOrder} />
        <div>
          <div className={styles.information}>
            <h2 data-testid='order-sent-header' className={styles.heading}>
              Catering order sent
            </h2>

            <p data-testid='confirmation-text' className={styles.confirmation}>
              {firstName ? `Thanks ${firstName}!` : 'Thanks!'} <br />
              {`Your catering order will be ${
                deliveryInfo ? 'delivered' : 'ready'
              }`}
            </p>

            <p data-testid='order-ready-time' className={styles.time}>
              {toRelativeDateString(
                estimatedFulfillmentDate!!,
                restaurantInfo.timeZoneId
              )}{' '}
              at{' '}
              {formatHours(estimatedFulfillmentDate, restaurantInfo.timeZoneId)}
            </p>
            {deliveryInfo ? (
              <p data-testid='delivery-info' className={styles.confirmation}>
                to{' '}
                {data.customLocationName && (
                  <>
                    {data.customLocationName}
                    <br />
                  </>
                )}
                {deliveryInfo.address1}
                {deliveryInfo.address2 ? ` ${deliveryInfo.address2}` : ''}
                , <br />
                {deliveryInfo.city}, {deliveryInfo.state} {deliveryInfo.zipCode}
              </p>
            ) : (
              <>
                {data.customLocationName && (
                  <p
                    data-testid='delivery-info'
                    className={styles.confirmation}
                  >
                    from {data.customLocationName}
                  </p>
                )}
              </>
            )}
            {deliveryInfo && deliveryInfo.notes && (
              <p data-testid='delivery-note-wrapper' className={styles.text}>
                <strong>Your instructions: </strong>
                <span data-testid='delivery-note'>{deliveryInfo.notes}</span>
              </p>
            )}
          </div>
          <div className={styles.emailConfirmationWrapper}>
            <p
              data-testid='email-confirmation-send-message'
              className={styles.text}
            >
              An email receipt has been sent.
            </p>
            <h2 data-testid='restaurant-name' className={styles.name}>
              {restaurantInfo?.name}
            </h2>
            {restaurantLocation && (
              <div className={styles.text}>
                <RestaurantAddress
                  address1={restaurantLocation.address1}
                  address2={restaurantLocation.address2 || undefined}
                  city={restaurantLocation.city}
                  state={restaurantLocation.state}
                />
              </div>
            )}
            {restaurantLocation.phone && (
              <p className={styles.text}>
                Issue with your order? Call {restaurantInfo.name}{' '}
                <a href={`tel:${restaurantLocation.phone}`}>
                  {formatPhoneNumber(restaurantLocation.phone)}
                </a>
              </p>
            )}
          </div>
          <LoyaltyConfirmation check={check} />
          <Fieldset label='Your catering order' collapsable>
            <CartTable cart={cart} editable={false} />
          </Fieldset>
          <div className={styles.cartFooter}>
            <CartFooter
              // @ts-ignore
              cart={cart}
              showTotal
              showTip={true}
            />
          </div>
          {(deliveryInfo ?? restaurantLocation) && (
            <ReplateBanner
              restaurantGuid={restaurantGuid}
              orderGuid={orderGuid!!}
              location={
                deliveryInfo ?? {
                  city: restaurantLocation!!.city,
                  state: restaurantLocation!!.state
                }
              }
            />
          )}
          <SocialBanner
            socialMediaLinks={restaurantInfo.socialMediaLinks}
            whiteLabelName={restaurantInfo.name}
          />
          <BackToMenuButton getRestaurantPath={getRestaurantPath} />
        </div>
        <div className={styles.closeButton}>
          <CloseButton
            data-testid='close-button-order-sent-page'
            to={getRestaurantPath()}
          />
        </div>
        <div className={styles.poweredByWrapper}>
          <PoweredByToast className={styles.poweredBy} />
        </div>
      </>
    </div>
  )
}

// eslint-disable-next-line import/no-default-export
export default ConfirmPage
