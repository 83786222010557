import cx from 'classnames'
import styles from './CartFooter.module.css'
import { Dollars } from '@local/do-secundo-dollars'
import React from 'react'
import { useLineItems } from '../../../utils/cart-helpers'
import { Cart } from '../../../types/cart'

interface Props {
  cart: Cart
  showTotal: boolean
  showTip: boolean
  excludeTax: boolean
  tip?: number
}

export const LineItems: React.FC<Props> = ({
  cart,
  showTotal,
  showTip,
  excludeTax,
  tip
}) => {
  const getLineItems = useLineItems({
    cart,
    showTip,
    showTotal,
    excludeTax
  })

  const lineItems = getLineItems(tip)

  return (
    <>
      {lineItems.map((lineItem) => {
        if (!lineItem) return null

        const { label, amount, id } = lineItem
        return (
          <tr
            data-testid={id}
            key={id}
            className={cx({
              [styles.total || '']:
                id === 'total-order-remaining-amount' ||
                id === 'total-order-amount'
            })}
          >
            <td className={styles.chargeInfo}>{label}</td>
            <td>
              <Dollars
                amount={Number(amount) || 0}
                renderWhenZero={
                  id === 'deliveryChargeTotal-amount' ? 'Free' : undefined
                }
              />
            </td>
          </tr>
        )
      })}
    </>
  )
}
