import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import styles from './Spotlight.module.css'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AnnouncementIcon } from '@toasttab/buffet-pui-icons'

export const Spotlight = () => {
  const { ooConfig } = useRestaurant()

  const [modalShown, setModalShown] = useState(false)
  const openModal = () => setModalShown(true)
  const closeModal = () => setModalShown(false)
  const [isOverflowing, setIsOverflowing] = useState(false)

  const containerRef = useRef(null)

  const setOverflowingOnResize = useCallback(() => {
    if (containerRef.current) {
      const { clientWidth, scrollWidth } = containerRef.current
      setIsOverflowing(scrollWidth > clientWidth)
    }
  }, [containerRef])

  useEffect(() => {
    setOverflowingOnResize()
    window.addEventListener('resize', setOverflowingOnResize)
    return () => window.removeEventListener('resize', setOverflowingOnResize)
  }, [setOverflowingOnResize, containerRef])

  if (!ooConfig?.spotlightHeaderText) return null

  return (
    <>
      <header className={styles.spotlight} role='banner'>
        <AnnouncementIcon accessibility='decorative' />
        <p
          data-testid='spotlight-header-content'
          className={styles.text}
          ref={containerRef}
        >
          {ooConfig.spotlightHeaderText}
        </p>
        {(ooConfig.spotlightBodyText || isOverflowing) && (
          <button className={styles.moreLink} onClick={openModal}>
            More
          </button>
        )}
      </header>
      {modalShown && (
        <Modal isOpen onRequestClose={closeModal}>
          <ModalHeader />
          <ModalBody className={styles.modalBody}>
            <p className={styles.header}>{ooConfig.spotlightHeaderText}</p>
            <p className={styles.body}>{ooConfig.spotlightBodyText}</p>
            <Button variant='link' onClick={closeModal}>
              Back to menu
            </Button>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
