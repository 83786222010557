import React from 'react'
import { SpecialMenuAvailability } from '../../FulfillmentDisplay/SpecialMenuFulfillmentDisplay'

export const FulfillmentHeader: React.FC<{
  editing: boolean
  displayName: string | undefined
}> = ({ editing, displayName }) => {
  return (
    <>
      <h2
        data-testid='scheduled-modal-header'
        className='pb-2 font-semibold type-large'
      >
        {displayName ||
          `${editing ? 'Update' : 'Find a time for'} your catering order`}
      </h2>
      {displayName ? (
        <SpecialMenuAvailability className='pb-4 font-normal type-subhead' />
      ) : (
        <p className='pb-4 font-normal type-subhead'>
          Fill out these details to be able to add items to your cart, or browse
          the menu and enter details later!
        </p>
      )}
    </>
  )
}
