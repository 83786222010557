import { ModifierGroup } from '@local/do-secundo-model'
import { ItemQuantityRuleView, QuantityRange } from '../types/config'
import { CooMenu, CooMenuGroup, CooMenuItem } from '../types/menu'
import { Selection } from '../types/orders'

const sortByPropertyPresence = (
  items: CooMenuItem[],
  property: keyof CooMenuItem
) => {
  return items.slice().sort((a, b) => {
    if (a[property] && b[property]) return 0
    if (b[property]) return 1
    if (!a[property] && !b[property]) return 0
    return -1
  })
}

const sortByImagePresence = (items: CooMenuItem[]) => {
  return sortByPropertyPresence(items, 'imageLink')
}

const sortByDescriptionPresence = (items: CooMenuItem[]) => {
  return sortByPropertyPresence(items, 'description')
}

const sortByStock = (items: CooMenuItem[]) => {
  return items.slice().sort((a, b) => {
    if (a.outOfStock && b.outOfStock) return 0
    if (a.outOfStock) return 1
    if (!a.outOfStock && !b.outOfStock) return 0
    return -1
  })
}

// used to match experiment variant to the sorting method
// see XP_MENU_ITEMS_SORT definition for details
const variantToSortMap = {
  1: sortByImagePresence,
  2: sortByDescriptionPresence
}

export const findItemInMenus = (
  menus: CooMenu[],
  groupGuid: string,
  itemGuid: string
) => {
  let targetItem: CooMenuItem | undefined

  menus.find((menu) => {
    walkMenu(menu, (group, item) => {
      if (group.guid === groupGuid && item.guid === itemGuid) {
        targetItem = item
        return false
      }

      return true
    })

    return !!targetItem
  })

  return targetItem
}

const walkMenu = (
  menu: Pick<CooMenu, 'groups'>,
  visit: (group: CooMenuGroup, item: CooMenuItem) => boolean
) => {
  const remainingGroups = [...menu.groups]

  while (remainingGroups.length > 0) {
    const group = remainingGroups.shift() as CooMenuGroup
    let shouldContinue = true
    for (const item of group.items) {
      shouldContinue = visit(group, item)

      if (!shouldContinue) {
        return
      }
    }

    remainingGroups.push(...group.subgroups)
  }
}

const walkMenuGroupsDepthFirst = (
  menu: Pick<CooMenu, 'groups'>,
  visit: (group: CooMenuGroup) => boolean
) => {
  const remainingGroups = [...menu.groups]

  while (remainingGroups.length > 0) {
    const group = remainingGroups.shift() as CooMenuGroup
    let shouldContinue = visit(group)

    if (!shouldContinue) {
      return
    }

    remainingGroups.unshift(...group.subgroups)
  }
}

export const getAllSubgroups = (menu: CooMenu) => {
  const groups: Record<string, CooMenuGroup> = {}

  walkMenuGroupsDepthFirst(menu, (group) => {
    groups[group.guid] = group
    return true
  })
  return groups
}

export const collectItemsByGroup = (
  group: CooMenuGroup
): Record<string, CooMenuItem[]> => {
  const itemsByGroup: Record<string, CooMenuItem[]> = {}
  walkMenu({ groups: [group] }, (menuGroup, item) => {
    if (!itemsByGroup[menuGroup.guid]) {
      itemsByGroup[menuGroup.guid] = []
    }

    itemsByGroup[menuGroup.guid].push(item)
    return true
  })

  return itemsByGroup
}

export const getSpecialRequest = (selection: Selection) => {
  return selection.modifiers.find(
    (mod) => mod.selectionType === 'SPECIAL_REQUEST'
  )?.displayName
}

export const setModifiersFromSelection = (
  selectionModifiers: Selection[],
  modifierGroups: ModifierGroup[],
  quantity: number
): ModifierGroup[] => {
  for (let selectionModifier of selectionModifiers) {
    if (selectionModifier.selectionType === 'SPECIAL_REQUEST') {
      continue
    }
    for (let modifierGroup of modifierGroups) {
      if (modifierGroup.guid === selectionModifier.optionGroup?.guid) {
        for (let modifier of modifierGroup.modifiers) {
          modifier.parent.guid = selectionModifier.optionGroup?.guid
          if (modifier.guid === selectionModifier.item.guid) {
            modifier.name = selectionModifier.displayName
            modifier._isSelected = true
            if (quantity && selectionModifier.quantity) {
              if (modifier.quantity > 0) {
                modifier.quantity += selectionModifier.quantity / quantity
              } else {
                modifier.quantity = selectionModifier.quantity / quantity
              }
            } else {
              modifier.quantity = 0
            }
            if (
              selectionModifier.modifiers &&
              modifier.modifierGroups &&
              selectionModifier.modifiers.length > 0 &&
              modifier.modifierGroups.length > 0
            ) {
              modifier.modifierGroups = setModifiersFromSelection(
                selectionModifier.modifiers,
                modifier.modifierGroups,
                quantity
              )
            }
          }
        }
      }
    }
  }
  return modifierGroups
}

export const setDefaultModifiers = (modifierGroups: ModifierGroup[]) => {
  modifierGroups.forEach((modifierGroup) => {
    modifierGroup.modifiers.forEach((modifier) => {
      if (modifier.isDefault) {
        modifier.quantity = 1
      }
      setDefaultModifiers(modifier.modifierGroups)
    })
  })
}

export const getItemQuantityRange = (
  itemGuid: string,
  groupGuid: string,
  rules: ItemQuantityRuleView[]
): QuantityRange | undefined =>
  rules.find(
    (rule) => rule.itemGuid === itemGuid && rule.groupGuid === groupGuid
  )?.range

export {
  sortByDescriptionPresence,
  sortByImagePresence,
  variantToSortMap,
  sortByStock
}
