import { formatISO } from 'date-fns'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import isEqual from 'lodash/isEqual'
import { toast } from 'react-toastify'

import { useAvailability } from '@local/do-secundo-availability-provider'
import { ErrorComponent } from '@local/do-secundo-error'
import { Progress } from '@local/do-secundo-progress'
import { useDiningOptions } from '@local/do-secundo-use-dining-options'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { FulfillmentFormValues } from '../../types/fulfillment'
import { getRxDateFromISO, isSameISOTime } from '../../utils/time-utils'
import { useGetCart } from '../CartQuery/CartQuery'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { DiningOptionSelector } from './DiningOptionSelector/DiningOptionSelector'
import { getDatesAndTimesFromResponse } from './fulfillment-helpers'
import { FulfillmentFooter } from './FulfillmentFooter/FulfillmentFooter'
import { FulfillmentHeader } from './FulfillmentHeader/FulfillmentHeader'
import { FulfillmentTimeSelector } from './FulfillmentTimeSelector/FulfillmentTimeSelector'
import { LocationPicker } from './LocationPicker/LocationPicker'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Link } from 'react-router-dom'
import { ArrowBackIcon } from '@toasttab/buffet-pui-icons'
import { DiningOptionBehavior } from '../../types/cart'

interface SelectorModalProps {
  onClose: () => void
}

export const FulfillmentSelectorModal: React.FC<SelectorModalProps> = ({
  onClose
}) => {
  const {
    diningOptionBehavior,
    deliveryInfo,
    fulfillmentDateTime,
    setFulfillment,
    selected,
    loading: fulfillmentLoading,
    fastLinkName,
    customLocationName,
    standardFulfillment
  } = useFulfillment()
  const { loading: availabilityLoading } = useAvailability()
  const { loading: cartLoading } = useGetCart()
  const [updateFulfillmentError, setUpdateFulfillmentError] = useState<Error>()
  const { isMobile, hasLimitedScreenHeight } = useGetWindowSize()

  const { restaurantInfo } = useRestaurant()

  const {
    isLoading,
    data,
    refetch,
    error: diningOptionsError,
    displayName,
    independent,
    fastLinkNotFound
  } = useDiningOptions()

  if (fastLinkNotFound) {
    return null
  }

  if (!restaurantInfo?.timeZoneId) {
    return null
  }

  const selectedDate = fulfillmentDateTime
    ? formatISO(
        getRxDateFromISO(fulfillmentDateTime, restaurantInfo?.timeZoneId)!!,
        {
          representation: 'date'
        }
      )
    : undefined

  if (
    isLoading ||
    cartLoading ||
    fulfillmentLoading ||
    availabilityLoading ||
    !data
  ) {
    return (
      <Modal isOpen={true} onRequestClose={onClose}>
        <Progress />
      </Modal>
    )
  }

  const error = diningOptionsError || updateFulfillmentError
  const retry = diningOptionsError ? refetch : undefined

  if (error) {
    return (
      <Modal isOpen={true} onRequestClose={onClose}>
        <ErrorComponent error={error} retry={retry} isActionable />
      </Modal>
    )
  }

  const {
    timeList: initialTimes,
    currentDate,
    diningOptionBehavior: selectedDiningOptionBehavior
  } = getDatesAndTimesFromResponse(data, selectedDate, diningOptionBehavior)

  const validationSchema = Yup.object().shape({
    diningOptionBehavior: Yup.string(),
    fulfillmentDate: Yup.string().nullable(),
    fulfillmentTime: Yup.string().nullable(),
    deliveryInfo: Yup.object().when(
      ['diningOptionBehavior', 'customLocationName'],
      {
        is: (
          diningOptionBehavior: DiningOptionBehavior,
          customLocationName: string | undefined
        ) =>
          diningOptionBehavior === 'DELIVERY' &&
          customLocationName === undefined,
        then: Yup.object().shape({
          latitude: Yup.string().required('Required'),
          longitude: Yup.string().required('Required')
        }),
        otherwise: Yup.object()
      }
    )
  })

  let currentTime
  if (initialTimes) {
    currentTime = initialTimes[0]
    if (fulfillmentDateTime) {
      const matchingTime = initialTimes.find((timeSlot) =>
        isSameISOTime(timeSlot.time, fulfillmentDateTime)
      )
      if (matchingTime) {
        currentTime = matchingTime
      }
    }
  }

  const initialValues: FulfillmentFormValues = {
    diningOptionBehavior: selectedDiningOptionBehavior,
    fulfillmentDate: currentDate ? currentDate.date : undefined,
    fulfillmentTime: currentTime ? currentTime.time : undefined,
    customLocationName: customLocationName,
    standardFulfillment: standardFulfillment,
    deliveryInfo: deliveryInfo,
    savedAddresses: [],
    fastLinkName: fastLinkName
  }

  const handleSubmit = async (values: FulfillmentFormValues) => {
    try {
      await setFulfillment(
        {
          fulfillmentDateTime: values.fulfillmentTime!!,
          diningOptionBehavior: values.diningOptionBehavior!!,
          deliveryInfo: values.deliveryInfo,
          customLocationName: values.customLocationName,
          standardFulfillment: values.standardFulfillment
        },
        values.fastLinkName
      )
      onClose()
      if (
        values.diningOptionBehavior === 'DELIVERY' &&
        !isEqual(initialValues.deliveryInfo, values.deliveryInfo)
      ) {
        toast('Delivery address updated')
      }
    } catch (error: any) {
      setUpdateFulfillmentError(error)
    }
  }

  const initialValid = validationSchema.isValidSync(initialValues)
  const hasFulfillmentDates = data.length > 0

  return (
    <Formik
      isInitialValid={initialValid}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { values, handleSubmit, setValues } = formik
        return (
          <Modal
            testId='FulfillmentModal'
            isOpen={true}
            size='xxl'
            overflowBehavior={
              isMobile || hasLimitedScreenHeight ? 'modal' : 'none'
            }
            className={hasLimitedScreenHeight ? 'min-h-full' : undefined}
            onRequestClose={hasFulfillmentDates ? onClose : undefined}
          >
            <Form onSubmit={handleSubmit}>
              <ModalHeader>
                {hasFulfillmentDates && !!displayName && !independent && (
                  <>
                    <Button
                      variant='link'
                      as={Link}
                      iconLeft={<ArrowBackIcon aria-label='view all dates' />}
                      to={'/'}
                    >
                      View all dates
                    </Button>
                  </>
                )}
              </ModalHeader>
              <ModalBody data-testid='FulfillmentSelectorModal'>
                <FulfillmentHeader
                  editing={selected}
                  displayName={displayName}
                />
                <DiningOptionSelector
                  values={values}
                  data={data}
                  setValues={setValues}
                />
                {!displayName && <LocationPicker fulfillmentValues={values} />}
                <FulfillmentTimeSelector data={data} formik={formik} />
              </ModalBody>
              <ModalFooter>
                <FulfillmentFooter
                  formik={formik}
                  editing={selected}
                  noAvailableDates={!hasFulfillmentDates}
                />
              </ModalFooter>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}
