import { BaseSchema, object, string } from 'yup'
import { DeliveryInfo, PaymentType } from '../../types/orders'
import * as DynamicFormSection from '../DynamicForms/DynamicFormSection'
import * as CheckoutDeliveryInfo from './CheckoutDeliveryInfo/CheckoutDeliveryInfo'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import * as Tip from './Tip/Tip'
import { FulfillmentContextType } from '../FulfillmentProvider/FulfillmentProvider'
import { EventType, FormValue } from '../../types/form'
import { PreComputedTip } from '../../types/cart'

export const formModules = [
  CustomerInfo,
  CheckoutDeliveryInfo,
  Tip,
  DynamicFormSection
]

export const whenNewCredit = ({
  then,
  otherwise = object().optional()
}: {
  then: BaseSchema
  otherwise?: BaseSchema
}) =>
  object().when(['paymentType', 'paymentTip'], {
    is: (paymentType: PaymentType, paymentTip: number) => {
      if (paymentType !== 'CREDIT') return false
      if (paymentTip > 0) return true
      return true
    },
    then,
    otherwise
  })

export const getValidationSchema = ({
  fulfillmentContext,
  formFields,
  country
}) => {
  // To be refactored into their own form modules.
  const baseSchemaValidationSchema = {
    paymentType: string()
      .trim()
      .required('Required')
      .oneOf(['CREDIT', 'OTHER'], 'must be a valid payment type'),
    encryptedCard: whenNewCredit({
      // The validation only seems to work with string() even though this value is
      // actually an object. Note that the value itself should be opaque since it
      // came from the iframe so we really only care whether it is present or not.
      then: object().required('Required')
    }),
    taxExemptId: string()
      .trim()
      .when('isTaxExempt', {
        is: true,
        then: string().required('An ID is required for tax exemption'),
        otherwise: string().notRequired()
      }),
    taxExemptState: string()
      .trim()
      .when('isTaxExempt', {
        is: true,
        then: string().required(
          `A ${
            country === 'US' ? 'State' : 'Province'
          } is required for tax exemption`
        ),
        otherwise: string().notRequired()
      })
  }

  const validationSchema = formModules.reduce(
    (acc, formModule) => ({
      ...acc,
      ...(formModule.getValidationSchema
        ? formModule.getValidationSchema({
            fulfillmentContext,
            formFields
          })
        : null)
    }),
    baseSchemaValidationSchema
  )

  return object().shape(validationSchema)
}

export interface ArgsForSubmit {
  paymentType: PaymentType
  ccInput: {
    cartGuid?: string
    customerGuid?: string
    customer: CustomerInfo.Customer
    newAddress?: {
      deliveryInfo: DeliveryInfo
    }
    newCardInput?: CardInput
    tipAmount: number
  }
  formValues: FormValue[]
  loyaltyEnroll?: boolean
  taxExemptId?: string
  taxExemptState?: string
  companyName?: string
  shouldLookupLoyalty: boolean
}

interface CardInput {
  encryptionKeyId: string
  encryptedCardDataString: string
  cardFirst6: string
  cardLast4: string
  expMonth: string
  expYear: string
  zipCode: string
}

export const getArgsForSubmit = ({
  fulfillmentContext,
  values,
  eventType
}: {
  fulfillmentContext: FulfillmentContextType
  values: CheckoutFormValues
  eventType: EventType
}): ArgsForSubmit => {
  let { paymentType } = values

  const customer = CustomerInfo.getArgsForSubmit({ values })

  const deliveryInfoArgs = CheckoutDeliveryInfo.getArgsForSubmit({
    fulfillmentContext,
    values
  })

  const tipArgs = Tip.getArgsForSubmit({
    values
  })

  const formArgs = DynamicFormSection.getArgsForSubmit(eventType, values)

  return {
    paymentType: paymentType,
    ccInput: {
      ...customer,
      ...deliveryInfoArgs,
      ...tipArgs,
      newCardInput: paymentType === 'CREDIT' ? values.encryptedCard : undefined
    },
    formValues: formArgs,
    loyaltyEnroll: values.loyaltyEnroll,
    taxExemptId: values.taxExemptId,
    taxExemptState: values.taxExemptState,
    companyName: values.companyName,
    shouldLookupLoyalty: values.optedInToLoyaltyLookup
  }
}

export interface CheckoutFormValues {
  encryptedCard: CardInput | undefined
  paymentType: PaymentType
  paymentTip: string
  loyaltyEnroll?: boolean
  isTaxExempt: boolean
  taxExemptId?: string
  taxExemptState?: string
  companyName?: string
  preComputedTips?: PreComputedTip
  optedInToLoyaltyLookup: boolean
}

export const getInitialValues = ({
  fulfillmentContext,
  formFields,
  customer,
  paymentType,
  preComputedTips
}): CheckoutFormValues => {
  const initialValues: CheckoutFormValues = {
    encryptedCard: undefined,
    paymentType,
    paymentTip: '',
    isTaxExempt: false,
    optedInToLoyaltyLookup: false
  }

  return formModules.reduce(
    (acc, formModule) => ({
      ...acc,
      ...(formModule.getInitialValues
        ? formModule.getInitialValues({
            fulfillmentContext,
            formFields,
            customer,
            preComputedTips
          })
        : null),
      gcNumber: ''
    }),
    initialValues
  )
}
