import { placeOrder, PlaceOrderRequest } from '../../api/cart'
import {
  AuthorizePaymentInput,
  AuthorizePaymentResponse,
  PaymentState
} from '../../apollo/generated/TakeoutWebGraphQLOperations'
import { Cart } from '../../types/cart'
import { PaymentType } from '../../types/orders'
import { SERVICES } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { ArgsForSubmit } from '../CheckoutForm/utils'
import { logError } from '@local/do-secundo-error'

interface HandlePlaceOrderArgs extends ArgsForSubmit {
  cart: Cart
  generateReCaptchaToken: (action: string) => Promise<string | undefined>
  doAuthorization: (
    input: Omit<AuthorizePaymentInput, 'restaurantGuid'>
  ) => Promise<AuthorizePaymentResponse | undefined>
  customerGuid?: string
  paymentType: PaymentType
  navigateOnSuccess: (guid: string) => void
  deleteCartGuid: (fastLinkName: string | undefined) => void
  setErrorMessage: (msg: Error | string) => void
  logCriticalError: (msg: string) => void
  fastLinkName: string | undefined
}

export const handlePlaceOrder = async ({
  cart,
  ccInput,
  paymentType,
  generateReCaptchaToken,
  doAuthorization,
  navigateOnSuccess,
  customerGuid,
  deleteCartGuid,
  setErrorMessage,
  logCriticalError,
  formValues,
  loyaltyEnroll,
  taxExemptId,
  taxExemptState,
  companyName,
  shouldLookupLoyalty,
  fastLinkName
}: HandlePlaceOrderArgs) => {
  try {
    let token: string | undefined = undefined
    try {
      token = await generateReCaptchaToken('authorize_payment')
    } catch (e: any) {
      logError(e)
      setErrorMessage(
        'Failed to load reCAPTCHA. Please reload the page and try again.'
      )
      return
    }

    ccInput.cartGuid = cart.guid
    if (customerGuid) {
      ccInput.customerGuid = customerGuid
    }

    let paymentInput: PlaceOrderRequest['paymentInput'] = {
      paymentType
    }

    if (paymentType === 'CREDIT') {
      let amount = cart.order.checks[0].totalAmount
      if (Boolean(taxExemptId)) {
        amount -= cart.order.checks[0].taxAmount
      }

      const payment = await doAuthorization({
        newCardInput: ccInput.newCardInput,
        tipAmount: ccInput.tipAmount,
        amount: amount,
        email: ccInput.customer.email,
        reCaptchaToken: token,
        cartGuid: cart.guid,
        fastLinkName: fastLinkName
      })

      if (
        payment?.paymentState === PaymentState.Denied ||
        payment?.paymentState === PaymentState.FraudReject ||
        payment?.denialReason?.toLowerCase().includes('trans denied')
      ) {
        setErrorMessage('Your payment transaction was denied.')
        return
      } else if (payment?.denialReason) {
        setErrorMessage(payment.denialReason)
        return
      } else if (
        !payment ||
        !payment.paymentGuid ||
        payment.denialReason?.toLowerCase().includes('invalid request')
      ) {
        setErrorMessage(
          'Unexpected error occurred processing your payment, please try again.'
        )
        return
      }

      paymentInput = {
        ...paymentInput,
        tipAmount: ccInput.tipAmount,
        paymentType: 'CREDIT',
        paymentGuid: payment?.paymentGuid as string | undefined
      }
    }

    const request: PlaceOrderRequest = {
      formValues,
      paymentInput,
      customer: ccInput.customer,
      customerGuid,
      deliveryInfo: ccInput.newAddress?.deliveryInfo,
      loyaltyEnroll,
      taxExemptId,
      taxExemptState,
      companyName,
      shouldLookupLoyalty,
      fastLinkName
    }

    const placedCart = await placeOrder(cart.guid, request)
    const { guid } = placedCart.order
    deleteCartGuid(fastLinkName)
    navigateOnSuccess(guid)
  } catch (e: any) {
    if (e.code === 'CRITICAL_ERROR') {
      logCriticalError(SERVICES.PLACE_ORDER)
    }

    if (e instanceof Response) {
      logError(await e.json())
    } else {
      logError(e)
    }

    setErrorMessage('Unable to place order, please try again.')
  }
}
