import React, { useState } from 'react'
import { normalize } from 'path'
import { useQuery } from 'react-query'
import {
  getApplicableConfigs,
  getRestaurantConfig
} from '../../../../client/api/restaurant'
import {
  ApplicableConfigsRequest,
  BrandingConfig,
  ConfigItemType,
  CustomLocationsConfig,
  RestaurantInfo,
  SpecialInstructionsConfig
} from '../../../../client/types/config'

interface RestaurantProviderProps {
  restaurantGuid: string
}

interface ApplicableConfigsMap {
  brandingConfig: BrandingConfig | undefined
  customLocations: CustomLocationsConfig | undefined
  fulfillmentInstructions: string | undefined
}

interface RestaurantContextType {
  getRestaurantPath: (path?: string) => string
  restaurantGuid: string
  setBasePath: (basePath: string) => void
  applicableConfigs: ApplicableConfigsMap
  updateApplicableConfigsState: (value: ApplicableConfigsRequest) => void
}

// @ts-ignore
const RestaurantContext = React.createContext<RestaurantContextType>({
  applicableConfigs: {
    brandingConfig: undefined,
    customLocations: undefined,
    fulfillmentInstructions: undefined
  }
})

export const RestaurantProvider: React.FC<RestaurantProviderProps> = ({
  restaurantGuid,
  children
}) => {
  const [basePath, setBasePath] = useState<string>('/')

  const getRestaurantPath = (subpath = '') => {
    if (subpath && subpath[0] !== '/') {
      subpath = `/${subpath}`
    }

    return normalize(`${basePath}${subpath}`)
  }

  const [applicableConfigsRequest, setApplicableConfigsRequest] =
    useState<ApplicableConfigsRequest>({})

  const updateApplicableConfigsState = (newState: ApplicableConfigsRequest) => {
    setApplicableConfigsRequest({ ...applicableConfigsRequest, ...newState })
  }

  const { data: applicableConfigsResp } = useQuery(
    ['applicable-configs', applicableConfigsRequest],
    () => getApplicableConfigs(applicableConfigsRequest)
  )

  const brandingConfig = applicableConfigsResp?.configItems.find(
    (config) => config.type === ConfigItemType.BRANDING
  )?.value as BrandingConfig | undefined

  const customLocationsConfig = applicableConfigsResp?.configItems.find(
    (config) => config.type === ConfigItemType.CUSTOM_LOCATIONS
  )?.value as CustomLocationsConfig | undefined

  const fulfillmentInstructions = applicableConfigsResp?.configItems.find(
    (config) => config.type === ConfigItemType.SPECIAL_FULFILLMENT_INSTRUCTIONS
  )?.value as SpecialInstructionsConfig | undefined

  const applicableConfigs = {
    brandingConfig: brandingConfig?.enabled ? brandingConfig : undefined,
    customLocations: customLocationsConfig,
    fulfillmentInstructions: fulfillmentInstructions?.instructions
  }

  const context = {
    getRestaurantPath,
    restaurantGuid,
    setBasePath,
    applicableConfigs,
    updateApplicableConfigsState
  }

  return (
    <RestaurantContext.Provider value={context}>
      {children}
    </RestaurantContext.Provider>
  )
}

/*
 * If there's a name override in the branding config, use that.
 * Otherwise, use the combined "[Rx name] [Location name]" string.
 */
const formatRestaurantName = (
  brandingConfig: BrandingConfig | undefined,
  restaurantInfo: RestaurantInfo
) => {
  const rxNameWithoutLocation = restaurantInfo.name
    .replace(restaurantInfo.locationName ?? '', '')
    .trim()

  if (
    !brandingConfig?.restaurantName ||
    brandingConfig.restaurantName === rxNameWithoutLocation
  ) {
    return restaurantInfo.name
  }

  return brandingConfig.restaurantName
}

export const useRestaurant = () => {
  const context = React.useContext(RestaurantContext)

  const {
    data: restaurantConfig,
    isLoading: loading,
    isError: error
  } = useQuery('oo-config', () => getRestaurantConfig(), {
    staleTime: 5 * 60 * 1000
  })

  const { brandingConfig } = context.applicableConfigs
  const rxName =
    restaurantConfig &&
    formatRestaurantName(brandingConfig, restaurantConfig.restaurantInfo)
  const rxPhone =
    brandingConfig?.phone ?? restaurantConfig?.restaurantInfo.address.phone

  return {
    ...context,
    ooConfig: restaurantConfig?.onlineOrderingConfig,
    restaurantInfo: restaurantConfig
      ? {
          ...restaurantConfig.restaurantInfo,
          name: rxName!!,
          address: {
            ...restaurantConfig.restaurantInfo.address,
            phone: rxPhone
          }
        }
      : undefined,
    loading,
    error
  }
}
